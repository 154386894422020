import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Col, Nav, Row } from "react-bootstrap";
import user1 from "../assets/images/mdi_register.svg";
import user2 from "../assets/images/material-symbols_explore.svg";
import user3 from "../assets/images/icon-park-solid_buy.svg";
import user4 from "../assets/images/physicalPrint-icon.png";
import vector6 from "../assets/images/vector6.png";
import vector7 from "../assets/images/Vector7.png";
import vector8 from "../assets/images/Vector8.png";
import { Link, useNavigate } from "react-router-dom";
import star from "../assets/images/star.svg"
const HowTerpnashWorks = () => {
  const [works, setWorks] = useState('User')
  const navigate = useNavigate();
  const usertype =(e) =>{
    setWorks(e)
  }
  const scrolltab = (e) => {
    navigate("/", { state: { targetId: e } });
    // setPageID(e)
    // const splitUrl =  pathname.split('/')
    // console.log(splitUrl);
    // if(splitUrl.includes('blogs')){
    //   // scrolltab()
    // }
    // var element = document.getElementById(e);

    // if (pageID === "features") {
    //   var headerOffset = -250;
    // } else {
    //   var headerOffset = 70;
    // }
    
    // var elementPosition = element.getBoundingClientRect().top;
    // var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    // window.scrollTo({
    //   top: offsetPosition,
    //   behavior: "smooth",
    // });
  };

  return (
    <>
      <div className="howterpnashWorks">
        <img src={star} className="star"  width="auto" height="auto" />
        <img src={star} className="stars"  width="auto" height="auto" />
        <img src={star} className="star1"  width="auto" height="auto" />
        <img src={star} className="star-bottom"  width="auto" height="auto" />

        <Container>
          <Row>
            <Col lg="12 " className="panelHead">
              <h3>How Terpnash Works</h3>
            </Col>
            <Col lg="12 text-center">
              <div className="tabs">
                <button className={works === 'User' ? "active":''}  onClick={()=>usertype('User')}>User</button>
                <button className={works === 'Artist/Museum' ? "active":''} onClick={()=>usertype('Artist/Museum')}>Artist/Museum</button>
              </div>
            </Col>
          </Row>
          {works === 'User' ? (
            <Row className="justify-content-center">
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs register text-center">
                  <div className="iconBox">
                    <img src={user1} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="rou">Register</h3>

                  <p className="text-center mx-auto">
                    Sign up and create your account to access Terpnash.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs physicalPrint text-center">
                  <div className="iconBox">
                    <img src={user4} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="rou">Order a Physical Print</h3>

                  <p className="text-center mx-auto">
                  Get a beautiful physical print delivered right to your door.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                  <Nav.Link  target="_blank" rel="noopener" href="https://market.terpnash.com/print-option"><button className="LearnMoreBtn mt-0">  <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3322 7.08337L18.3238 7.08337L18.3154 7.08394L18.1798 7.09307L18.1714 7.09364L18.163 7.09478C17.5526 7.17757 17.0822 7.70031 17.0822 8.33337V8.34184L17.0828 8.35028L17.092 8.48598L17.0925 8.49433L17.0937 8.50263C17.1764 9.11306 17.6992 9.58337 18.3322 9.58337H25.3142L16.3347 18.5642L12.5509 14.7805L12.5444 14.774L12.5374 14.768L12.4253 14.6712L12.4183 14.6652L12.4109 14.6597C11.9214 14.2965 11.2271 14.3366 10.7831 14.7804L10.7831 14.7805L2.78311 22.7805L2.77659 22.787L2.77057 22.794L2.67374 22.9062L2.66776 22.9132L2.66229 22.9205C2.29906 23.4101 2.33918 24.1043 2.7831 24.5483L2.78311 24.5483C3.27126 25.0365 4.06273 25.0365 4.55088 24.5483L11.667 17.4321L15.4509 21.2159L15.4574 21.2224L15.4643 21.2283L15.5764 21.3251L15.5834 21.3312L15.5908 21.3367C16.0804 21.7 16.7747 21.6597 17.2187 21.2159L17.2187 21.2159L27.0837 11.349L27.0842 18.3386V18.347L27.0848 18.3555L27.094 18.4912L27.0945 18.4996L27.0957 18.5079C27.1785 19.1182 27.7013 19.5886 28.3342 19.5886C29.0247 19.5886 29.5842 19.0289 29.5842 18.3386L29.5837 8.29536V8.28293L29.5824 8.27055L29.5699 8.14495L29.5685 8.13126L29.5657 8.1178L29.5475 8.03247L29.5454 8.02265L29.5426 8.01303L29.5171 7.92759L29.513 7.91378L29.5073 7.90054L29.4557 7.78012L29.449 7.76439L29.4402 7.74972L29.3679 7.62956L29.3604 7.61716L29.3516 7.6057L29.2936 7.53053L29.2868 7.52173L29.2793 7.51356L29.225 7.45484L29.2251 7.45471L29.218 7.44762C29.1098 7.3395 28.9853 7.25401 28.8505 7.19469L28.8505 7.19467L28.8473 7.19332L28.7669 7.15925L28.7521 7.15299L28.7367 7.14867L28.6049 7.11185L28.5887 7.10732L28.572 7.105L28.4383 7.08644L28.4212 7.08407L28.4039 7.08407L18.3322 7.08337Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </svg></button></Nav.Link>
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs explore text-center ">
                  <div className="iconBox">
                    <img src={user2} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="roun11">Explore</h3>
                  <div class="roun1 mx-auto"></div>
                  <p className="text-center mx-auto">
                    Browse through the diverse collection of physical arts.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs text-center buy">
                  <div className="iconBox">
                    <img src={user3} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="roun-21">Buy/Resell</h3>
                  <div class="roun2 mx-auto"></div>
                  <p className="text-center mx-auto">
                    Engage in purchasing or reselling NFT artworks.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
          ):(
            <Row className="justify-content-center">
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs register text-center">
                  <div className="iconBox">
                    <img src={user1} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="rou">Register</h3>

                  <p className="text-center mx-auto">
                  Create your account to access Terpnash's features.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs physicalPrint text-center">
                  <div className="iconBox">
                    <img src={user4} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="rou">Physical Print Option</h3>

                  <p className="text-center mx-auto px-1">
                  Offer your customers a beautiful high quality physical print.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                  <Nav.Link  target="_blank" rel="noopener" href="https://market.terpnash.com/print-option"><button className="LearnMoreBtn mt-0">  <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3322 7.08337L18.3238 7.08337L18.3154 7.08394L18.1798 7.09307L18.1714 7.09364L18.163 7.09478C17.5526 7.17757 17.0822 7.70031 17.0822 8.33337V8.34184L17.0828 8.35028L17.092 8.48598L17.0925 8.49433L17.0937 8.50263C17.1764 9.11306 17.6992 9.58337 18.3322 9.58337H25.3142L16.3347 18.5642L12.5509 14.7805L12.5444 14.774L12.5374 14.768L12.4253 14.6712L12.4183 14.6652L12.4109 14.6597C11.9214 14.2965 11.2271 14.3366 10.7831 14.7804L10.7831 14.7805L2.78311 22.7805L2.77659 22.787L2.77057 22.794L2.67374 22.9062L2.66776 22.9132L2.66229 22.9205C2.29906 23.4101 2.33918 24.1043 2.7831 24.5483L2.78311 24.5483C3.27126 25.0365 4.06273 25.0365 4.55088 24.5483L11.667 17.4321L15.4509 21.2159L15.4574 21.2224L15.4643 21.2283L15.5764 21.3251L15.5834 21.3312L15.5908 21.3367C16.0804 21.7 16.7747 21.6597 17.2187 21.2159L17.2187 21.2159L27.0837 11.349L27.0842 18.3386V18.347L27.0848 18.3555L27.094 18.4912L27.0945 18.4996L27.0957 18.5079C27.1785 19.1182 27.7013 19.5886 28.3342 19.5886C29.0247 19.5886 29.5842 19.0289 29.5842 18.3386L29.5837 8.29536V8.28293L29.5824 8.27055L29.5699 8.14495L29.5685 8.13126L29.5657 8.1178L29.5475 8.03247L29.5454 8.02265L29.5426 8.01303L29.5171 7.92759L29.513 7.91378L29.5073 7.90054L29.4557 7.78012L29.449 7.76439L29.4402 7.74972L29.3679 7.62956L29.3604 7.61716L29.3516 7.6057L29.2936 7.53053L29.2868 7.52173L29.2793 7.51356L29.225 7.45484L29.2251 7.45471L29.218 7.44762C29.1098 7.3395 28.9853 7.25401 28.8505 7.19469L28.8505 7.19467L28.8473 7.19332L28.7669 7.15925L28.7521 7.15299L28.7367 7.14867L28.6049 7.11185L28.5887 7.10732L28.572 7.105L28.4383 7.08644L28.4212 7.08407L28.4039 7.08407L18.3322 7.08337Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </svg></button></Nav.Link>
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs explore text-center ">
                  <div className="iconBox">
                    <img src={user2} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="roun11">Upload/Explore Art</h3>
                  <div class="roun1 mx-auto"></div>
                  <p className="text-center mx-auto">
                  Digitize your physical artwork or discover new pieces.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="howterp">
                <div className="Featureboxs text-center buy">
                  <div className="iconBox">
                    <img src={user3} className="img-fluid mx-auto " alt="" />
                  </div>
                  <h3 className="roun-21">Sell/Buy</h3>
                  <div class="roun2 mx-auto"></div>
                  <p className="text-center mx-auto">
                  Seamlessly sell or purchase artwork on our platform.
                  </p>
                  <svg
                    className="partnerDesign"
                    width="414"
                    height="89"
                    viewBox="0 0 414 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
          )}
          
          <Row>
            <Col lg="12 text-center">
            
              <button onClick={() => scrolltab('learningResource')} className="LearnMoreBtn">
              
                Learn More
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3322 7.08337L18.3238 7.08337L18.3154 7.08394L18.1798 7.09307L18.1714 7.09364L18.163 7.09478C17.5526 7.17757 17.0822 7.70031 17.0822 8.33337V8.34184L17.0828 8.35028L17.092 8.48598L17.0925 8.49433L17.0937 8.50263C17.1764 9.11306 17.6992 9.58337 18.3322 9.58337H25.3142L16.3347 18.5642L12.5509 14.7805L12.5444 14.774L12.5374 14.768L12.4253 14.6712L12.4183 14.6652L12.4109 14.6597C11.9214 14.2965 11.2271 14.3366 10.7831 14.7804L10.7831 14.7805L2.78311 22.7805L2.77659 22.787L2.77057 22.794L2.67374 22.9062L2.66776 22.9132L2.66229 22.9205C2.29906 23.4101 2.33918 24.1043 2.7831 24.5483L2.78311 24.5483C3.27126 25.0365 4.06273 25.0365 4.55088 24.5483L11.667 17.4321L15.4509 21.2159L15.4574 21.2224L15.4643 21.2283L15.5764 21.3251L15.5834 21.3312L15.5908 21.3367C16.0804 21.7 16.7747 21.6597 17.2187 21.2159L17.2187 21.2159L27.0837 11.349L27.0842 18.3386V18.347L27.0848 18.3555L27.094 18.4912L27.0945 18.4996L27.0957 18.5079C27.1785 19.1182 27.7013 19.5886 28.3342 19.5886C29.0247 19.5886 29.5842 19.0289 29.5842 18.3386L29.5837 8.29536V8.28293L29.5824 8.27055L29.5699 8.14495L29.5685 8.13126L29.5657 8.1178L29.5475 8.03247L29.5454 8.02265L29.5426 8.01303L29.5171 7.92759L29.513 7.91378L29.5073 7.90054L29.4557 7.78012L29.449 7.76439L29.4402 7.74972L29.3679 7.62956L29.3604 7.61716L29.3516 7.6057L29.2936 7.53053L29.2868 7.52173L29.2793 7.51356L29.225 7.45484L29.2251 7.45471L29.218 7.44762C29.1098 7.3395 28.9853 7.25401 28.8505 7.19469L28.8505 7.19467L28.8473 7.19332L28.7669 7.15925L28.7521 7.15299L28.7367 7.14867L28.6049 7.11185L28.5887 7.10732L28.572 7.105L28.4383 7.08644L28.4212 7.08407L28.4039 7.08407L18.3322 7.08337Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </svg>
              </button>
          
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HowTerpnashWorks;
