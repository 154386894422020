import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import why1 from '../assets/images/why1.png'
import why2 from '../assets/images/why2.png'
import why3 from '../assets/images/why3.svg'
import why4 from '../assets/images/why4.svg'
import why5 from '../assets/images/physicalprint-new.png'
import whyterpnash from '../assets/images/jpg/whyterpnashImg.png'
import star from "../assets/images/star.svg"


function Whyterpnash() {
  return (
    <div className="whyTerpneshMain">
     <svg width="661" className="topleftdesign" height="584" viewBox="0 0 661 584" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.79508 1C-8.53132 35.9971 -2.80059 109.853 142.734 125.3C324.651 144.609 536.556 48.0651 577.537 330.053C610.322 555.644 646.173 591.66 660 581.469" stroke="#7874F4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M63.2938 1C49.2931 26.6766 54.5281 80.863 187.474 92.1961C353.657 106.363 547.233 35.5306 584.67 242.419C614.619 407.93 647.369 434.354 660 426.877" stroke="#7874F4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M120.784 1C108.132 14.7703 112.863 43.8305 233 49.9085C383.172 57.5059 558.098 19.5187 591.928 130.473C618.992 219.236 648.586 233.408 660 229.398" stroke="#7874F4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
      <Container>
        <Row>
          <Col lg="8 my-auto">
            <Row>
              <Col lg="12 panelHead">
                <h3>Why Terpnash</h3>
                <img src={star} className="starsss"  width="auto" height="auto" />
                <img src={star} className="btm-starsss"  width="auto" height="auto" />
                <p className="mt-4">
                We honor the palpable texture of physical art and embrace the limitless potential of the digital sphere.
                </p>
              </Col>
            </Row>
            <Row className="paddleft6rem">
                <Col lg="6">
                    <div className="whyinnBox">
                        <div className="imgBox">
                            <img src={why1} alt="">
                        </img>
                        </div>
                        <div className="contentBox"> 
                            <h3>100% Physical Art to Digital</h3>
                            <p>Transform your physical artworks into digital masterpieces.</p>
                        </div>
                    </div>
                </Col>
                <Col lg="6">
                    <div className="whyinnBox">
                        <div className="imgBox">
                            <img src={why2} alt="">
                        </img>
                        </div>
                        <div className="contentBox"> 
                            <h3>Museum Asset Sales</h3>
                            <p>Museums can showcase art to reach a wider audience beyond physical walls. </p>
                        </div>
                    </div>
                </Col>
                <Col lg="6">
                    <div className="whyinnBox">
                        <div className="imgBox">
                            <img src={why5} alt="">
                        </img>
                        </div>
                        <div className="contentBox"> 
                            <h3>Physical Print</h3>
                            <p>Offer your customers the option of a physical print.</p>
                        </div>
                    </div>
                </Col>
                
                <Col lg="6">
                    <div className="whyinnBox">
                        <div className="imgBox">
                            <img src={why4} alt="">
                        </img>
                        </div>
                        <div className="contentBox"> 
                            <h3>Present Global Art</h3>
                            <p>Be part of a diverse and vibrant community of artists worldwide.</p>
                        </div>
                    </div>
                </Col>
                <Col lg="6">
                    <div className="whyinnBox">
                        <div className="imgBox">
                            <img src={why3} alt="">
                        </img>
                        </div>
                        <div className="contentBox"> 
                            <h3>Up to 20% of Royalty</h3>
                            <p>Earn up to 20% royalty on each NFT resale, ensuring fair recognition and reward.</p>
                        </div>
                    </div>
                </Col>

            </Row>
          </Col>
          <Col lg="3" className="text-end ms-auto">
            <img src={whyterpnash} alt="" className="w-100 "></img>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Whyterpnash;
